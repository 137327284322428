import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import SearchAndFilter from './components/searchAndFilter';
import * as fcl from '@blocto/fcl';
import { ToastContainer, toast } from 'react-toastify';
import "./homepage.css";
import { ThreeDots } from "react-loader-spinner";
import pageImages from "../../assets/pageImages";
import courtierImages from "../../assets/courtierImages";
import royalImages from "../../assets/royalImages";
import { GlobalContext } from "../../components/globalContext/globalState";
import { ListingsContext } from "../../contexts/listingsContextNew";  // adjust path/casing if needed
import { purchasePageListing } from "../../cadence/transactions/purchasePageListing";
import seductionSampler from "../../assets/packs/seductionSampler.png";
import penthousePleasures from "../../assets/packs/penthousePleasures.png";
import royalFlush from "../../assets/packs/royalFlush.png";


function HomePage({ fetchFlowBalance }) {
  const { setSelectedItem } = useContext(GlobalContext);
  const { singleNFTs, packs, isLoaded } = useContext(ListingsContext);  
  const [user, setUser] = useState({ loggedIn: null });
  const [userAddress, setUserAddress] = useState(null);
  const [selectedType, setSelectedType] = useState("PagesX");
  const [selectedCurrency, setSelectedCurrency] = useState("FLOW");
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingCardID, setProcessingCardID] = useState(null);
  const [visibleRows, setVisibleRows] = useState(3); // Each row = 5 items, so 3 rows = 15 items
  const [removedListingIDs, setRemovedListingIDs] = useState(new Set());


  const nftTypes = ["PagesX", "CourtiersX", "RoyalsX", "PacksX"];
  const currencies = ["FLOW", "Sloppy", "USDC", "TIT", "Vroom", "Dust"];
  
  // States for search and filtering:
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  
  // Local refs and memo for images:
  const imageCache = useMemo(() => new Map(), []);
  const storyRowRef = useRef(null);
  const imageRowRef = useRef(null);

  const pagesListings = [];
  const courtiersListings = [];

  
  useEffect(() => {
    fcl.currentUser.subscribe((currentUser) => {
      setUser(currentUser);
      setUserAddress(currentUser.addr);
    });
  }, []);
  
  // When switching tabs, reset search state and visibleRows.
  useEffect(() => {
    setSearchTerm("");
    setFilters({});
    setVisibleRows(3);
  }, [selectedType, selectedCurrency]);


  useEffect(() => {
    if (isLoaded) {
      console.log("🚀 Single NFTs by Type and Currency:", singleNFTs);
      console.log("🎁 Packs by Size and Currency:", packs);

      // To see specific categories clearly:
      console.log("📄 Single Pages (FLOW):", singleNFTs.PagesX.FLOW);
      console.log("👑 Single Royals (USDC):", singleNFTs.RoyalsX.USDC);
      console.log("🎒 3-Pack Listings (TIT):", packs[3].TIT);
      console.log("🎒 5-Pack Listings (FLOW):", packs[5].FLOW);
      console.log("🎒 10-Pack Listings (Sloppy):", packs[10].Sloppy);
    }
  }, [singleNFTs, packs, isLoaded]);
  
  const listingsToDisplay = useMemo(() => {
    if (selectedType === "PacksX") {
      return packs[3][selectedCurrency]
        .concat(packs[5][selectedCurrency])
        .concat(packs[10][selectedCurrency]);
    }
    return singleNFTs[selectedType][selectedCurrency] || [];
  }, [packs, singleNFTs, selectedType, selectedCurrency]);

  // Apply search and filter logic.
  const displayedListings = useMemo(() => {
    return listingsToDisplay
      .filter((listing) => {
        const listingID = listing.listingData?.listingID || listing.listingID;
        if (removedListingIDs.has(listingID)) return false;
  
        const name = listing.name || `${listing.listingData?.nftIDs?.flat().length}-Card Pack` || "";
        const matchesSearch = name.toLowerCase().includes(searchTerm.toLowerCase());
  
        const matchesFilters = Object.keys(filters).every((category) =>
          filters[category].size === 0 ||
          listing.traits?.some(
            (trait) => trait.category === category && filters[category].has(trait.name)
          )
        );
  
        return matchesSearch && matchesFilters;
      });
  }, [listingsToDisplay, searchTerm, filters, removedListingIDs]);
  
  
  // Optional: Randomize a subset of traits (if desired).
  const pickRandomTraits = (traits) => {
    if (!traits || traits.length === 0) return [];
    if (traits.length <= 2) return traits;
    let shuffled = [...traits].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 2);
  };
  
  // stableRandomTraits holds the displayed listings (after filtering) and applies randomization on traits.
  const stableRandomTraits = useMemo(() => {
    return displayedListings.map((item) => ({
      ...item,
      traits: pickRandomTraits(item.traits),
    }));
  }, [displayedListings]);
  
  // When the SearchAndFilter component calls onFilter, update the search term and filters.
  const handleFilterChange = (newSearchTerm, newFilters = {}) => {
    setSearchTerm(newSearchTerm);
    setFilters(newFilters);
  };
  
  // Handler for when an item is selected from search suggestions.
  const handleSelectItem = (selectedItem, type) => {
    setSearchTerm(selectedItem.name);
    // Optionally, you could trigger additional behavior here.
  };
  
  // Refresh handler: resets search term, filters, and visibleRows.
  const handleRefresh = () => {
    setSearchTerm("");
    setFilters({});
    setVisibleRows(3);
  };

  const displayCurrency = (currency) => {
    const currencyMap = {
      FLOW: "$FLOW",
      Sloppy: "$LOPPY",
      USDC: "$USDC",
      TIT: "TIT$",
      Vroom: "$VROOM",
      Dust: "$ÐUST",
    };
    return currencyMap[currency] || currency;
  };
  
  
  // Purchase handler remains unchanged.
  const handleBuy = async (page) => {
    try {
      setIsProcessing(true);
      setProcessingCardID(page.nftId);
      const txid = await fcl.mutate({
        cadence: purchasePageListing,
        args: (arg, t) => [
          arg("0xfdfe39186c5e3b90", t.Address),
          arg(page.listingData.listingID, t.UInt64),
          arg(page.listingData.price, t.UFix64),
          arg(selectedCurrency.toLowerCase(), t.String), // 👈 pass selected currency as ftType
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      await fcl.tx(txid).onceSealed();
      await fetchFlowBalance(userAddress);

      // 👇 Remove purchased listing locally
      setRemovedListingIDs((prev) => {
        const newSet = new Set(prev);
        newSet.add(page.listingData.listingID);
        return newSet;
      });

      setIsProcessing(false);
      setProcessingCardID(null);
    } catch (error) {
      console.error("Error during purchase:", error);
      setIsProcessing(false);
      setProcessingCardID(null);
    }
  };

  const handlePackBuy = async (page) => {
    try {
      console.log("Data: ", page)


      setIsProcessing(true);
      setProcessingCardID(page.listingData.listingID);
      const txid = await fcl.mutate({
        cadence: purchasePageListing,
        args: (arg, t) => [
          arg("0xfdfe39186c5e3b90", t.Address),
          arg(page.listingData.listingID, t.UInt64),
          arg(page.listingData.details.salePrice, t.UFix64),
          arg(selectedCurrency.toLowerCase(), t.String), // 👈 pass selected currency as ftType
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      await fcl.tx(txid).onceSealed();
      await fetchFlowBalance(userAddress);

      // 👇 Remove purchased listing locally
      setRemovedListingIDs((prev) => {
        const newSet = new Set(prev);
        newSet.add(page.listingData.listingID);
        return newSet;
      });

      setIsProcessing(false);
      setProcessingCardID(null);
    } catch (error) {
      console.error("Error during purchase:", error);
      setIsProcessing(false);
      setProcessingCardID(null);
      console.log("Data on Error: ", page)
    }
  };
  
  
  // See More handler: loads 3 more rows (i.e. 15 more items) at a time.
  const handleSeeMore = () => setVisibleRows(prev => prev + 3);

  // Render helpers.
  const renderImage = (thumbnail, type, size = null) => {
    const imageMap = {
      PagesX: pageImages,
      CourtiersX: courtierImages,
      RoyalsX: royalImages,
    };
  
    if (type === "PacksX") {
      if (size === 3) return seductionSampler;
      if (size === 5) return penthousePleasures;
      if (size === 10) return royalFlush;
    }
  
    return imageMap[type]?.[thumbnail] || "";
  };

  const renderTrait = (trait, idx) => (
    <div key={idx} className="trait">
      <div className="trait-row-1">
        <div className="trait-category-name">{trait.name}</div>
        <div className="trait-value">{trait.value}</div>
      </div>
      <div className="trait-row-2">
        <div className="trait-description">
          <small>{trait.description}</small>
        </div>
      </div>
    </div>
  );

  const renderTile = (nft, idx) => {
    const isPack = selectedType === "PacksX";
    const listing = nft.listingData || nft;
    const nftIDs = listing.details?.nftIDs?.flat() || [];
    const packSize = nftIDs.length;
  
    const packNames = {
      3: "Seduction Sampler Pack",
      5: "Penthouse Pleasures Pack",
      10: "Royal Flush Pack",
    };
  
    const packImages = {
      3: seductionSampler,
      5: penthousePleasures,
      10: royalFlush,
    };
  
    const ftAmount = parseFloat(listing.details?.ftAmount || "0.00").toFixed(2);
    const price = parseFloat(listing.details?.salePrice || "0.00").toFixed(2);
  
    if (isPack) {
      return (
        <div
          className="portrait-tile-home pack-tile"
          key={idx}
          style={{
            border: '1px solid #cba135',
            padding: '1rem',
            borderRadius: '1rem',
            backgroundColor: '#003b3d',
            color: '#ffffff', // ✅ Pure white for all text
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <h3 style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
            color: "#ffffff", // ✅ Title in white
            textShadow: "0 0 6px #ffe0e9aa",
            marginBottom: "1rem",
            textAlign: "center"
          }}>
            {packNames[packSize] || "Mystery Pack"}
          </h3>
    
          <img
            src={packImages[packSize]}
            alt={packNames[packSize]}
            style={{
              width: "100%",
              borderRadius: "12px",
              boxShadow: "0 6px 12px rgba(0,0,0,0.4)"
            }}
          />
    
          <div className="pack-details-text">
            <p><strong>Includes:</strong> {packSize} random cards + {ftAmount} TIT$</p>
            <p><strong>Price:</strong> {price} {displayCurrency(selectedCurrency)}</p>
          </div>
          
          {processingCardID === listing.listingID ? (
            <div className="loading-spinner-buy" style={{ marginTop: "1rem" }}>
              <ThreeDots height="40" width="80" color="#ffffff" />
            </div>
          ) : (
            <button
              className="see-more-button"
              onClick={() => handlePackBuy({ listingData: listing })}
              style={{
                marginTop: "1rem",
                backgroundColor: "#4B0082",
                color: "#ffffff", // ✅ Button text in white
                fontWeight: "bold",
                fontSize: "1rem",
                border: "none",
                padding: "0.6rem 1.2rem",
                borderRadius: "6px",
                cursor: "pointer",
                boxShadow: "0 0 8px #cba135"
              }}
            >
              BUY PACK
            </button>
          )}
        </div>
      );
    }
    
  
    // fallback for singles (unchanged)
    return (
      <div className="portrait-tile-home" key={idx}>
        <div className="name-hp-container">
          <h3>{nft.name}</h3>
          <div className="hp-container">
            <span className="hp-value">{nft.hp} HP</span>
          </div>
        </div>
        <div className="image-banner-2">
          <img src={renderImage(nft.thumbnail, selectedType)} alt={nft.name} />
          <div className="description-banner-new">{nft.description}</div>
        </div>
        <div className="portrait-details">
          {nft.traits.map((trait, idx) => renderTrait(trait, idx))}
        </div>
        <div className="purchase-section">
          <div className="price-column">
            {`${parseFloat(nft.listingData?.price).toFixed(2)} ${displayCurrency(selectedCurrency)}`}
          </div>
          {processingCardID === nft.nftId ? (
            <div className="loading-spinner-buy">
              <ThreeDots height="40" width="80" color="#4B0082" />
            </div>
          ) : (
            <button className="see-more-button" onClick={() => handleBuy(nft)}>
              BUY
            </button>
          )}
        </div>
      </div>
    );
  };
  
  
  

  const filterActive = Object.values(filters).some((set) => set.size > 0);
  
  return (
    <div className="homepage">
      <h1>Bazaar</h1>
      <SearchAndFilter
        onFilter={handleFilterChange}
        onSelectItem={(item) => setSearchTerm(item.name)}
        pageDetails={singleNFTs.PagesX[selectedCurrency]}
        courtierDetails={singleNFTs.CourtiersX[selectedCurrency]}
        royalDetails={singleNFTs.RoyalsX[selectedCurrency]} // 👈 Add this line
        selectedType={selectedType.replace("X", "").toLowerCase()}
      />


    <div className="type-selector">
      <h3 className="selector-header">Type</h3>
      {nftTypes.map((type) => (
        <button
          key={type}
          onClick={() => setSelectedType(type)}
          className={`type-button ${selectedType === type ? "active" : ""}`}
        >
          {type.replace("X", "")}
        </button>
      ))}
    </div>

    <div className="currency-selector">
      <h3 className="selector-header">Currency</h3>
      {currencies.map((currency) => (
        <button
          key={currency}
          onClick={() => setSelectedCurrency(currency)}
          className={`type-button ${selectedCurrency === currency ? "active" : ""}`}
        >
          {displayCurrency(currency)}
        </button>
      ))}
    </div>

      <div className="image-row">
        {!isLoaded ? (
          <div className="loading-spinner">
            <ThreeDots height="80" width="80" color="#007d7e" />
          </div>
        ) : displayedListings.length === 0 ? (
          <div>No listings found for {selectedType.replace("X", "")} in {selectedCurrency}</div>
        ) : (
          stableRandomTraits.slice(0, visibleRows * 5).map(renderTile)

        )}
      </div>

      <div className="load-more-container">
        {displayedListings.length > visibleRows * 5 ? (
          <button onClick={handleSeeMore} className="see-more-button-real">
            SEE MORE
          </button>
        ) : (
          <button onClick={() => setVisibleRows(3)} className="see-more-button-real">
            REFRESH
          </button>
        )}
      </div>

      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} />
    </div>
  );
}

export default HomePage;
