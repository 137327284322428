import React, { useState } from 'react';
import './searchAndFilter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const SearchAndFilter = ({ onFilter, onSelectItem, pageDetails, courtierDetails, royalDetails, selectedType }) => { // 👈 royalDetails added
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [noMatches, setNoMatches] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  const getFilteredData = () => {
    if (selectedType === 'pages') {
      return Array.isArray(pageDetails) ? pageDetails : [];
    } else if (selectedType === 'courtiers') {
      return Array.isArray(courtierDetails) ? courtierDetails : [];
    } else if (selectedType === 'royals') { // 👈 Add royals handling here
      return Array.isArray(royalDetails) ? royalDetails : [];
    }
    return [];
  };

  const handleSelectItem = (item) => {
    onSelectItem(item, selectedType);
    setSearchTerm('');
    setFilteredResults([]);
    setActiveIndex(-1);
    setNoMatches(false);
    setIsFocused(false);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value.trim());
    setActiveIndex(-1);

    if (value.trim().length > 0) {
      const lowerCaseValue = value.toLowerCase();
      const data = getFilteredData();

      const nameMatches = new Map();
      const traitMatches = [];

      data.forEach((item) => {
        if (item.name.toLowerCase().includes(lowerCaseValue)) {
          if (!nameMatches.has(item.name)) {
            nameMatches.set(item.name, {
              name: item.name,
              description: item.description,
              matchingTraits: [],
            });
          }
        }

        item.traits.forEach((trait) => {
          if (
            trait.name.toLowerCase().includes(lowerCaseValue) ||
            (trait.description &&
              trait.description.toLowerCase().includes(lowerCaseValue))
          ) {
            traitMatches.push({
              name: item.name,
              traitName: trait.name,
              traitDescription: trait.description,
            });
          }
        });
      });

      const combinedResults = [
        ...Array.from(nameMatches.values()),
        ...traitMatches.slice(0, 3),
      ].slice(0, 3);

      setFilteredResults(combinedResults);
      setNoMatches(combinedResults.length === 0);
    } else {
      setFilteredResults([]);
      setNoMatches(false);
    }
  };

  return (
    <div
      className="search-container"
      onBlur={() => setFilteredResults([])}
      onFocus={() => setIsFocused(true)}
    >
      <button
        className="filter-button"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </button>

      <input
        type="text"
        placeholder={!isFocused ? `Search ${selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}` : ''}
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />

      {filteredResults.length > 0 && (
        <div className="search-results">
          {filteredResults.map((result, index) => (
            <div
              key={`${result.name || result.traitName}-${index}`}
              className={`search-result-item ${index === activeIndex ? 'active' : ''}`}
              onMouseDown={() => handleSelectItem(result)}
            >
              {result.name && <strong>{result.name}</strong>}
              {result.traitName && (
                <ul style={{ color: '#ffe0e9', marginTop: '5px' }}>
                  <li>{result.traitName}: {result.traitDescription}</li>
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
      {noMatches && (
        <div className="search-results">
          <div className="no-matches">No matches found</div>
        </div>
      )}
    </div>
  );
};

export default SearchAndFilter;
