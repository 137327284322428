import React, { useState, useEffect, useContext } from 'react';
import * as fcl from '@blocto/fcl';
import { UserContext } from '../../contexts/userContext';
import { getModelDetails } from '../../cadence/scripts/getModelDetailsX1';
import { getCourtierDetails } from '../../cadence/scripts/getCourtierDetails';
import { getStakedDetails } from '../../cadence/scripts/getStakedDetails';
import { stakePortraits } from '../../cadence/transactions/stakePortraits';
import { unstakePortraits } from '../../cadence/transactions/unstakePortraits';
import { claimStakingRewards } from '../../cadence/transactions/claimStakingRewards';
import pagesData from '../../cadence/data/pages.json';
import courtiersData from '../../cadence/data/courtiers.json';
import { getStakedEarnings } from '../../cadence/scripts/getStakedEarnings';
import { ThreeDots } from "react-loader-spinner";
import './brothel.css';

// Example XP/Level functions
const getNextLevelXP = (level) => 5 * (2 ** level);
const computeLevel = (count) => {
  let level = 0;
  let cumulativeXP = 0;
  let nextLevelXP = getNextLevelXP(level);
  while (count >= cumulativeXP + nextLevelXP) {
    level += 1;
    cumulativeXP += nextLevelXP;
    nextLevelXP = getNextLevelXP(level);
  }
  return level;
};

export default function Brothel() {
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const { flowAddress, loading } = useContext(UserContext);
    const [portraits, setPortraits] = useState([]);
    const [stakedPortraits, setStakedPortraits] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [selectedPages, setSelectedPages] = useState([]);
    const [selectedCourtiers, setSelectedCourtiers] = useState([]);
    const [selectedStaked, setSelectedStaked] = useState([]); // For unstaking
    const [isProcessing, setIsProcessing] = useState(false);
    const [earnings, setEarnings] = useState(null);

    // Track the entire process of fetching and subscribing
    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            console.log("User subscription triggered:", currentUser);
            setUser(currentUser);
            setUserAddress(currentUser.addr);
        });
    }, []);

    // Fetch staked NFTs
    const fetchStakedPortraits = async (address) => {
      try {
          const stakedResult = await fcl.query({
              cadence: getStakedDetails,
              args: (arg, t) => [arg(address, t.Address)]
          });
  
          console.log("Staked NFTs: ", stakedResult);
  
          const enrichedStaked = stakedResult.map(stakedNFT => ({
              ...stakedNFT,
              isStaked: true
          }));
  
          setStakedPortraits(enrichedStaked);
      } catch (error) {
          console.error('Error fetching staked NFTs:', error);
      }
  };

  const fetchEarnings = async (address) => {
    try {
      const earnings = await fcl.query({
        cadence: getStakedEarnings,
        args: (arg, t) => [arg(address, t.Address)]
      });

      setEarnings(earnings);

    } catch (error) {
        console.error('Error fetching total earnings: ', error);
    }
  }

  const handleStake = async (nftId, nftType, nftName, level) => {
    try {
      setIsProcessing(true);
      const txid = await fcl.mutate({
        cadence: stakePortraits,
        args: (arg, t) => [
          arg([nftId], t.Array(t.UInt64)), // Send as an array
          arg([nftType], t.Array(t.String)), 
          arg([nftName], t.Array(t.String)), 
          arg([level], t.Array(t.UInt64))
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      await fcl.tx(txid).onceSealed();

      // Refetch the data after successful staking
      await fetchPortraits(userAddress);
      await fetchStakedPortraits(userAddress);
      await fetchEarnings(userAddress);
      
      setIsProcessing(false);
    } catch (error) {
      console.error("Error during staking:", error);
      setIsProcessing(false);
    }
  }

  const handleUnstake = async (nftId, nftType) => {
    try {
      setIsProcessing(true);
      const txid = await fcl.mutate({
        cadence: unstakePortraits,
        args: (arg, t) => [
          arg([nftId], t.Array(t.UInt64)), // Send as an array
          arg([nftType], t.Array(t.String))
        ],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      await fcl.tx(txid).onceSealed();

      // Refetch the data after successful staking
      await fetchPortraits(userAddress);
      await fetchStakedPortraits(userAddress);
      await fetchEarnings(userAddress);
      
      setIsProcessing(false);
    } catch (error) {
      console.error("Error during staking:", error);
      setIsProcessing(false);
    }
  }

  const handleClaimEarnings = async () => {
    try {
      setIsProcessing(true);
      const txid = await fcl.mutate({
        cadence: claimStakingRewards,
        args: (arg, t) => [],
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        authorizations: [fcl.currentUser],
        limit: 999,
      });
      await fcl.tx(txid).onceSealed();

      await fetchEarnings(userAddress);

      setIsProcessing(false);
    } catch (error) {
      console.error("Error during staking:", error);
      setIsProcessing(false);
    }
  }



    // Fetch NFT data (both Pages and Courtiers) from the blockchain
    const fetchPortraits = async (address) => {
      setIsFetching(true);
      try {
        const [pagesResult, courtiersResult] = await Promise.all([
          fcl.query({
            cadence: getModelDetails,
            args: (arg, t) => [arg(address, t.Address)],
          }),
          fcl.query({
            cadence: getCourtierDetails,
            args: (arg, t) => [arg(address, t.Address)],
          }),
        ]);
        
        const allPortraits = [...pagesResult, ...courtiersResult];
        console.log("All Portraits: ", allPortraits);
  
        setPortraits(allPortraits);
      } catch (error) {
        console.error("Error fetching portraits:", error);
      } finally {
        setIsFetching(false);
      }
    };

    useEffect(() => {
      if (userAddress) {
          fetchStakedPortraits(userAddress);
          fetchPortraits(userAddress);
          fetchEarnings(userAddress);
      }
    }, [userAddress]);

    // Group NFTs by name and calculate total count
    const groupByName = (nfts) => {
      const grouped = nfts.reduce((acc, nft) => {
        const name = nft.nftData.name;
        if (!acc[name]) {
          acc[name] = {
            name,
            ids: [],  // Store multiple ids for the same name
            level: 0,
            count: 0,
          };
        }
        acc[name].ids.push(nft.id);
        acc[name].count += 1;
        acc[name].level = computeLevel(acc[name].count);
        return acc;
      }, {});
      
      // Convert to array and keep ids
      return Object.values(grouped);
    };



    // Filter NFTs for staking (not staked) and group them by name
    const pagesPortraits = groupByName(
      portraits.filter(p =>
        pagesData.Pages.some(page => 
          page.alt.split(' - ')[0].trim().toLowerCase() === p.nftData.name.toLowerCase()
        ) && 
        !stakedPortraits.some(staked => staked.nftName === p.nftData.name)
      )
    );

    const courtiersPortraits = groupByName(
      portraits.filter(p =>
        courtiersData.Courtiers.some(courtier => 
          courtier.Name.toLowerCase() === p.nftData.name.toLowerCase()
        ) && 
        !stakedPortraits.some(staked => staked.nftName === p.nftData.name)
      )
    );


    const stakedPages = stakedPortraits.filter(nft => nft.nftType === 'pages');
    const stakedCourtiers = stakedPortraits.filter(nft => nft.nftType === 'courtiers');

    // Handlers for multi-select changes
    const handlePagesChange = (e) => {
      const selected = Array.from(e.target.selectedOptions, (option) => option.value);
      setSelectedPages(selected);
    };

    const handleCourtiersChange = (e) => {
      const selected = Array.from(e.target.selectedOptions, (option) => option.value);
      setSelectedCourtiers(selected);
    };

    const handleStakedChange = (e) => {
      const selected = Array.from(e.target.selectedOptions, (option) => option.value);
      setSelectedStaked(selected);
    };

    return (
      <div className="brothel-page">
        <div className="homepage-header-landing">
          <h1>Brothel</h1>
        </div>

        {/* Earnings Section */}
        <div className="section-title-claim">
            <h2>Claim</h2>
          </div>
        <div className="earnings-section">

          <h3 className="stake-title-claim">Earnings</h3>
          <div className="earnings-display">
            {earnings !== null ? (
              <span>{parseFloat(earnings).toFixed(2)} TIT$</span>
            ) : (
              <ThreeDots height="15" width="30" color="#fff" />
            )}
          </div>
          <button
            className="see-more-button"
            onClick={handleClaimEarnings}
            disabled={isProcessing || earnings === null || earnings <= 0}
          >
            {isProcessing ? (
              <ThreeDots height="15" width="30" color="#fff" />
            ) : (
              "CLAIM"
            )}
          </button>
        </div>

            
        {/* Stake NFTs Section */}
        <div className="stake-section">
          <div className="section-title">
            <h2>Stake</h2>
          </div>
          <div className="portrait-list two-columns">
            {/* Pages Section */}
            <div className="stake-box">
              <h3 className="stake-title">Pages</h3>
              {pagesPortraits.map((item, index) => (
                <div key={item.name} className={`portrait-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                  <span className="portrait-name">{item.name}</span>
                  <span className="portrait-level">Level {item.level}</span>
    
                  {/* Stake Button */}
                  <button
                    className="stake-button"
                    onClick={() => handleStake(item.ids[0], "pages", item.name, item.level)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? (
                      <ThreeDots height="15" width="30" color="#fff" />
                    ) : (
                      "STAKE"
                    )}
                  </button>
                </div>
              ))}
            </div>
    
            {/* Courtiers Section */}
            <div className="stake-box">
              <h3 className="stake-title">Courtiers</h3>
              {courtiersPortraits.map((item, index) => (
                <div key={item.name} className={`portrait-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                  <span className="portrait-name">{item.name}</span>
                  <span className="portrait-level">Level {item.level}</span>
    
                  {/* Stake Button */}
                  <button
                    className="stake-button"
                    onClick={() => handleStake(item.ids[0], "courtiers", item.name, item.level)}
                    disabled={isProcessing}
                  >
                    {isProcessing ? (
                      <ThreeDots height="15" width="30" color="#fff" />
                    ) : (
                      "STAKE"
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
    
        {/* Unstake NFTs Section */}
        <div className="unstake-section">
          <div className="section-title">
            <h2>Unstake</h2>
          </div>
          <div className="portrait-list two-columns">
            {/* Pages Section */}
            <div className="stake-box">
              <h3 className="stake-title">Pages</h3>
              {stakedPages.map((nft, index) => (
                <div key={nft.nftID} className={`portrait-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                  <span className="portrait-name">{nft.nftName}</span>
                  <span className="portrait-level">Level {nft.level}</span>
    
                  {/* Unstake Button */}
                  <button
                    className="stake-button"
                    onClick={() => handleUnstake(nft.nftID, "pages")}
                    disabled={isProcessing}
                  >
                    {isProcessing ? (
                      <ThreeDots height="15" width="30" color="#fff" />
                    ) : (
                      "UNSTAKE"
                    )}
                  </button>
                </div>
              ))}
            </div>
    
            {/* Courtiers Section */}
            <div className="stake-box">
              <h3 className="stake-title">Courtiers</h3>
              {stakedCourtiers.map((nft, index) => (
                <div key={nft.nftID} className={`portrait-row ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                  <span className="portrait-name">{nft.nftName}</span>
                  <span className="portrait-level">Level {nft.level}</span>
    
                  {/* Unstake Button */}
                  <button
                    className="stake-button"
                    onClick={() => handleUnstake(nft.nftID, "courtiers")}
                    disabled={isProcessing}
                  >
                    {isProcessing ? (
                      <ThreeDots height="15" width="30" color="#fff" />
                    ) : (
                      "UNSTAKE"
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
    
    
    
}
