export const unstakePortraits = `
import NonFungibleToken from 0x1d7e57aa55817448
import TitStakingX0X from 0xfdfe39186c5e3b90
import CourtiersX from 0xfdfe39186c5e3b90
import PagesX from 0xfdfe39186c5e3b90

transaction(nftIds: [UInt64], nftTypes: [String]) {
    let address: Address
    let stakingRef: &TitStakingX0X.TitStake
    let nfts: @{Int: {NonFungibleToken.NFT}}
    let collectionRefPages: &{PagesX.CollectionPublic}
    let collectionRefCourtiers: &{CourtiersX.CollectionPublic}

    prepare(signer: auth(Storage, BorrowValue, Capabilities) &Account) {
        self.address = signer.address

        // Borrow the staking resource.
        self.stakingRef = signer.storage.borrow<&TitStakingX0X.TitStake>(from: TitStakingX0X.StakingStoragePath)
            ?? panic("Staking resource not found!")

        // Borrow the user's NFT collection (must implement NonFungibleToken.Receiver)
        self.collectionRefCourtiers = signer.storage.borrow<&{CourtiersX.CollectionPublic}>(from: /storage/courtiersXCollection)
            ?? panic("Could not borrow a reference to the Courtiers NFT collection")

        self.collectionRefPages = signer.storage.borrow<&{PagesX.CollectionPublic}>(from: /storage/pagesXCollection)
            ?? panic("Could not borrow a reference to the Pages NFT collection")

        self.nfts <- {}
    }

    execute {
        // Loop through the NFTs to unstake
        for i in [nftIds.length -1] {
            let nftID = nftIds[i]
            let nftType = nftTypes[i]

            // Unstake the NFT
            let nft <- self.stakingRef.unstakeNFT(nftID: nftID, nftType: nftType, owner: self.address)

            // Deposit back to the appropriate collection
            if nftType == "pages" {
                self.collectionRefPages.deposit(token: <- nft)
            } else if nftType == "courtiers" {
                self.collectionRefCourtiers.deposit(token: <- nft)
            } else {
                panic("Unsupported NFT type")
            }
        }

        destroy self.nfts
    }
}
`
