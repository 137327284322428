export const purchasePageListing = `
import FungibleToken from 0xf233dcee88fe0abe
import NonFungibleToken from 0x1d7e57aa55817448
import TitPalaceStorefrontXxX from 0xfdfe39186c5e3b90
import PagesX from 0xfdfe39186c5e3b90
import CourtiersX from 0xfdfe39186c5e3b90
import RoyalsX from 0xfdfe39186c5e3b90
import TitToken from 0x66b60643244a7738
import VroomToken from 0xf887ece39166906e
import FlowToken from 0x1654653399040a61
import USDCFlow from 0xf1ab99c82dee3526
import SloppyStakes from 0x53f389d96fb4ce5e
import FlovatarDustToken from 0x921ea449dffec68a

transaction(storefrontAddress: Address, listingResourceID: UInt64, expectedPrice: UFix64, ftType: String) {

    // Local references
    let paymentVault: @{FungibleToken.Vault}
    var fungibleTokenVaultRef: auth(FungibleToken.Withdraw) &{FungibleToken.Vault}
    let nftCollections: {Type: &{NonFungibleToken.CollectionPublic}}
    let storefront: &TitPalaceStorefrontXxX.Storefront // Borrowing the actual Storefront resource
    let listingRef: &{TitPalaceStorefrontXxX.ListingPublic}
    let tokenVault: &{FungibleToken.Receiver}


    prepare(acct: auth(Storage, Capabilities) &Account) {
        self.nftCollections = {}

        self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow the buyer's vault for type FLOW")

        if ftType == "vroom" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/VroomTokenVault)
                ?? panic("Could not borrow the buyer's vault for type VROOM")
        } else if ftType == "flow" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/flowTokenVault)
                ?? panic("Could not borrow the buyer's vault for type FLOW")
        } else if ftType == "usdc" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/usdcFlowVault)
                ?? panic("Could not borrow the buyer's vault for type USDC")
        } else if ftType == "tit" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/TitTokenVault)
                ?? panic("Could not borrow the buyer's vault for type TIT")
        } else if ftType == "sloppy" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/SloppyStakesVault)
                ?? panic("Could not borrow the buyer's vault for type LOPPY")
        } else if ftType == "dust" {
            self.fungibleTokenVaultRef = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Vault}>(from: /storage/FlovatarDustTokenVault)
                ?? panic("Could not borrow the buyer's vault for type DUST")
        } else {
            panic("Unsupported token type")
        }

        // Borrow the storefront reference from the specified address
        self.storefront = getAccount(storefrontAddress)
            .capabilities.borrow<&TitPalaceStorefrontXxX.Storefront>(TitPalaceStorefrontXxX.StorefrontPublicPath)
            ?? panic("Could not borrow the storefront resource.")

        // Borrow a reference to the specific listing
        self.listingRef = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No listing found with the given ID.")

        // Get the sale price from the listing
        let salePrice = self.listingRef.getDetails().salePrice

        // Ensure the expected price matches the listing price
        assert(expectedPrice == salePrice, message: "The sale price does not match the expected price.")

        // Withdraw the sale price from the buyer's FungibleToken vault

        self.paymentVault <- self.fungibleTokenVaultRef.withdraw(amount: expectedPrice)

        // Ensure the buyer's NFT collection exists, or set it up
        if acct.storage.borrow<&PagesX.Collection>(from: PagesX.CollectionStoragePath) == nil {
            let collection <- PagesX.createEmptyCollection(nftType: Type<@PagesX.NFT>())
            acct.storage.save(<-collection, to: PagesX.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(PagesX.CollectionStoragePath),
                at: PagesX.CollectionPublicPath
            )
        }

        // Borrow the buyer's NFT collection
        self.nftCollections[Type<@PagesX.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: PagesX.CollectionStoragePath)
            ?? panic("Could not borrow the buyer's NFT collection.")

                // Ensure the buyer's NFT collection exists, or set it up
        if acct.storage.borrow<&CourtiersX.Collection>(from: CourtiersX.CollectionStoragePath) == nil {
            let collection <- CourtiersX.createEmptyCollection(nftType: Type<@CourtiersX.NFT>())
            acct.storage.save(<-collection, to: CourtiersX.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(CourtiersX.CollectionStoragePath),
                at: CourtiersX.CollectionPublicPath
            )
        }

        // Borrow the buyer's NFT collection
        self.nftCollections[Type<@CourtiersX.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: CourtiersX.CollectionStoragePath)
            ?? panic("Could not borrow the buyer's NFT collection.")


       if acct.storage.borrow<&RoyalsX.Collection>(from: RoyalsX.CollectionStoragePath) == nil {
            let collection <- RoyalsX.createEmptyCollection(nftType: Type<@RoyalsX.NFT>())
            acct.storage.save(<-collection, to: RoyalsX.CollectionStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(RoyalsX.CollectionStoragePath),
                at: RoyalsX.CollectionPublicPath
            )
        }

        // Borrow the buyer's NFT collection
        self.nftCollections[Type<@RoyalsX.NFT>()] = acct.storage.borrow<&{NonFungibleToken.CollectionPublic}>(from: RoyalsX.CollectionStoragePath)
            ?? panic("Could not borrow the buyer's NFT collection.")


        // Ensure the buyer's TitToken vault exists or set it up
        if acct.storage.borrow<&TitToken.Vault>(from: TitToken.VaultStoragePath) == nil {
            let vroomVault <- TitToken.createEmptyVault(vaultType: Type<@TitToken.Vault>())
            acct.storage.save(<-vroomVault, to: TitToken.VaultStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(TitToken.VaultStoragePath),
                at: TitToken.VaultReceiverPath
            )
        }

        // Borrow the buyer's TitToken vault
        self.tokenVault = acct.storage.borrow<&{FungibleToken.Receiver}>(from: TitToken.VaultStoragePath)
            ?? panic("Could not borrow the buyer's TitToken vault.")
    }

    execute {
        // Call the purchase method on the listing
        self.listingRef.purchase(
            payment: <-self.paymentVault,
            nftCollections: self.nftCollections,
            ftReceiver: self.tokenVault
        )

        // Remove the listing after successful purchase
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}
`