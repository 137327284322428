// listingContextNew.js
import React, { createContext, useState, useEffect } from 'react';
import * as fcl from '@blocto/fcl';
import { getAllListingDetails } from '../cadence/scripts/getAllListings';
import { getPageDetails } from '../cadence/scripts/getPageDetails';
import { getCourtierDetailsX1 } from '../cadence/scripts/getCourtierDetailsX1';
import { getRoyalsDetails } from '../cadence/scripts/getRoyalsDetails';

export const ListingsContext = createContext();

export const ListingsProvider = ({ children }) => {
  const [singleNFTs, setSingleNFTs] = useState({
    PagesX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
    CourtiersX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
    RoyalsX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
  });

  const [packs, setPacks] = useState({
    3: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
    5: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
    10: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
  });

  const [isLoaded, setIsLoaded] = useState(false);

  const getPaymentType = (vaultType) => {
    if (vaultType.includes('FlowToken.Vault')) return 'FLOW';
    if (vaultType.includes('SloppyStakes.Vault')) return 'Sloppy';
    if (vaultType.includes('USDCFlow.Vault')) return 'USDC';
    if (vaultType.includes('TitToken.Vault')) return 'TIT';
    if (vaultType.includes('VroomToken.Vault')) return 'Vroom';
    if (vaultType.includes('FlovatarDustToken.Vault')) return 'Dust';
    return 'Unknown';
  };

  const calculateHP = (traits) =>
    traits.reduce((total, trait) => total + (parseFloat(trait.value) || 0), 0);

  const fetchAllListings = async () => {
    try {
      return await fcl.query({
        cadence: getAllListingDetails,
        args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
      });
    } catch (error) {
      console.error("Failed to fetch listings:", error);
      return [];
    }
  };

  const fetchNFTDetails = async (cadenceScript) => {
    try {
      return await fcl.query({
        cadence: cadenceScript,
        args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
      });
    } catch (error) {
      console.error("Failed to fetch NFT details:", error);
      return [];
    }
  };

  const enrichListings = (listingsGroup, nftDataMap) =>
    listingsGroup
      .map((listing) => {
        const nftId = String(listing.details.nftIDs.flat().find(id => id));
        const nftDetails = nftDataMap.get(nftId);
        if (!nftDetails) {
          console.warn(`No NFT data found for ID: ${nftId}`);
          return null;
        }
        return {
          ...nftDetails,
          listingData: {
            listingID: listing.listingID,
            price: listing.details.salePrice,
          },
          hp: calculateHP(nftDetails.traits || []),
        };
      })
      .filter(Boolean);

  useEffect(() => {
    const loadData = async () => {
      const listings = await fetchAllListings();

      // Prepare groups for Pages, Courtiers, Royals
      const pagesIDs = new Set();
      const courtiersIDs = new Set();
      const royalsIDs = new Set();

      listings.forEach((listing) => {
        listing.details.nftIDs.forEach((ids, idx) => {
          const nftType = listing.details.nftTypes[idx]?.typeID;
          if (!nftType) return;  // <-- add this guard
          if (nftType.includes('PagesX')) ids.forEach(id => pagesIDs.add(String(id)));
          else if (nftType.includes('CourtiersX')) ids.forEach(id => courtiersIDs.add(String(id)));
          else if (nftType.includes('RoyalsX')) ids.forEach(id => royalsIDs.add(String(id)));
        });
      });
      

      // Fetch NFT details once per type
      const [pagesDetails, courtiersDetails, royalsDetails] = await Promise.all([
        fetchNFTDetails(getPageDetails),
        fetchNFTDetails(getCourtierDetailsX1),
        fetchNFTDetails(getRoyalsDetails),
      ]);

      const pagesMap = new Map(pagesDetails.map(nft => [String(nft.nftId), nft]));
      const courtiersMap = new Map(courtiersDetails.map(nft => [String(nft.nftId), nft]));
      const royalsMap = new Map(royalsDetails.map(nft => [String(nft.nftId), nft]));

      // Classify listings
      const singles = {
        PagesX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
        CourtiersX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
        RoyalsX: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
      };

      const packListings = {
        3: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
        5: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
        10: { FLOW: [], Sloppy: [], USDC: [], TIT: [], Vroom: [], Dust: [] },
      };

      listings.forEach(listing => {
        const paymentType = getPaymentType(listing.details.salePaymentVaultType.typeID);
        const totalNFTs = listing.details.nftIDs.flat().length;
        const nftTypes = listing.details.nftTypes.map(t => t.typeID);

        if (nftTypes.length === 1 && totalNFTs === 1) {
          const typeKey = nftTypes[0].includes('PagesX') ? 'PagesX'
            : nftTypes[0].includes('CourtiersX') ? 'CourtiersX'
            : 'RoyalsX';
          singles[typeKey][paymentType].push(listing);
        } else if ([3, 5, 10].includes(totalNFTs)) {
          packListings[totalNFTs][paymentType].push(listing);
        }
      });

      // Enrich singles listings
      Object.keys(singles).forEach(type => {
        const map = type === 'PagesX' ? pagesMap : type === 'CourtiersX' ? courtiersMap : royalsMap;
        Object.keys(singles[type]).forEach(currency => {
          singles[type][currency] = enrichListings(singles[type][currency], map);
        });
      });

      setSingleNFTs(singles);
      setPacks(packListings);
      setIsLoaded(true);

      console.log("🚀 Single NFTs:", singles);
      console.log("🎁 Packs:", packListings);
    };

    loadData();
  }, []);

  return (
    <ListingsContext.Provider value={{ singleNFTs, packs, isLoaded }}>
      {children}
    </ListingsContext.Provider>
  );
};

export default ListingsProvider;
