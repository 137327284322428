import { config } from "@onflow/fcl";

config({
  "flow.network": "mainnet",
  "accessNode.api": "https://rest-mainnet.onflow.org",
  "discovery.wallet": "https://fcl-discovery.onflow.org/authn",
  "discovery.authn.endpoint": "https://fcl-discovery.onflow.org/api/authn", // Required for API-based discovery
  "discovery.wallet.method": "TAB/RPC", // Or "TAB/RPC" to open in a new tab
  // Optionally, include opt‑in wallets if desired:
  // "discovery.authn.include": [
  //   "0xead892083b3e2c6c", // Dapper (Mainnet)
  //   "0xe5cd26afebe62781"  // Ledger (Mainnet)
  // ]
});