import React, { useContext, useState, useEffect, useCallback } from 'react';
import "./parlor.css";
import * as fcl from '@blocto/fcl';
import { getPortraitDetails } from '../../cadence/scripts/getPortraitDetails'; // Fetches user's portraits
import { getTitBalance } from '../../cadence/scripts/getTitBalance'; // Fetches Tit balance
import { getPortraitAccounts } from '../../cadence/scripts/getPortraitAccounts'; // Fetches mappings of IDs to Flow addresses
import { UserContext } from '../../contexts/userContext';
import { images } from '../../assets/images';
import { getNextLevelXP, getCurrentLevel } from '../../config/levelsconfig'; // XP config
import { getModelDetails } from '../../cadence/scripts/getModelDetailsX1';
import { getStakedDetails } from '../../cadence/scripts/getStakedDetails';
import { getCourtierDetails } from '../../cadence/scripts/getCourtierDetails';
import { getRoyalDetails } from '../../cadence/scripts/getRoyalDetails';
import courtiersData from '../../cadence/data/courtiers.json'; 
import pagesData from '../../cadence/data/pages.json';
import  pageImages from '../../assets/pageImages';
import courtierImages from '../../assets/courtierImages';
import { ThreeDots } from "react-loader-spinner";
import royalsData from '../../cadence/data/royals.json';
import royalImages from '../../assets/royalImages';


export default function Parlor() {
    const [portraits, setPortraits] = useState([]);  // Stores portraits owned by the user
    const [courtiers, setCortiers] = useState([]);
    const { flowAddress, loading } = useContext(UserContext);
    const [idToFlowMap, setIdToFlowMap] = useState({});  // Mapping of portrait IDs to Flow addresses
    const [balances, setBalances] = useState({});  // Mapping of Flow addresses to Tit balances
    const [isFetching, setIsFetching] = useState(true); // Track fetching status
    const [user, setUser] = useState({ loggedIn: null });
    const [userAddress, setUserAddress] = useState(null);
    const [userXP, setUserXP] = useState(0);  // Tracks user's total XP
    const [userLevel, setUserLevel] = useState(0);  // Tracks user's current level
    const [nextLevelXP, setNextLevelXP] = useState(0);  // Tracks XP needed for the next level
    const [stakedPortraits, setStakedPortraits] = useState([]);


    // Track the entire process of fetching and subscribing
    useEffect(() => {
        fcl.currentUser.subscribe(currentUser => {
            console.log("User subscription triggered:", currentUser);
            setUser(currentUser);
            setUserAddress(currentUser.addr);
    
            // 🔹 If the user is NOT logged in, immediately stop fetching
            if (!currentUser.loggedIn) {
                setIsFetching(false);
            }
        });
    }, []);
    // const fetchIdToFlowMap = useCallback(async () => {
    //     console.log("Fetching Flow address mappings...");
    //     try {
    //         const usersResult = await fcl.query({
    //             cadence: getPortraitAccounts,  // Cadence script to fetch Flow address mappings
    //         });
    //         console.log("Mappings fetched:", usersResult);
    
    //         const mapping = {};
    //         for (const user of usersResult) {
    //             if (user.titID && user.flowAddress) {
    //                 mapping[user.titID] = user.flowAddress;  // Map each titID to its Flow address
    //             }
    //         }
    
    //         console.log("Mappings processed:", mapping);
    //         setIdToFlowMap(mapping);
    //         fetchBalances(mapping);
    //     } catch (error) {
    //         console.error('Error fetching ID to Flow address mappings:', error);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (!loading && flowAddress) {
    //         console.log("Fetching portraits for flow address:", flowAddress);
    //         fetchPortraits(flowAddress);  // Fetch user's portraits
    //         fetchIdToFlowMap();  // Fetch the ID to Flow address mapping
    //     }
    // }, [flowAddress, loading, fetchIdToFlowMap]);

    // 🟢 First useEffect to fetch staked NFTs only when userAddress is set
    useEffect(() => {
        if (userAddress) {
            console.log("Fetching Staked NFTs for address:", userAddress);
            fetchStakedPortraits(userAddress);
        }
    }, [userAddress]);

    // 🟢 Second useEffect to fetch owned NFTs only after staked NFTs are fully set
    useEffect(() => {
        if (userAddress && stakedPortraits.length >= 0) {
            console.log("Staked NFTs loaded:", stakedPortraits);
            fetchPortraits(userAddress);
        }
    }, [stakedPortraits, userAddress]);


    useEffect(() => {
        console.log('Portraits loaded:', portraits);
    }, [portraits]);

    useEffect(() => {
        console.log('Popup images data:', images.popupImages);
    }, []);

    const calculateUserLevelAndXP = (portraits) => {
        console.log("Calculating XP from portraits:", portraits);
    
        // Group portraits by name and count occurrences, including staked NFTs
        const xpMap = portraits.reduce((acc, portrait) => {
            const name = portrait.nftData.name;
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});
    
        console.log("XP Map (name counts):", xpMap);
    
        // Calculate total XP
        const totalXP = Object.values(xpMap).reduce((sum, count) => sum + count, 0);
    
        let currentLevel = 0;
        let cumulativeXP = 0; // Total XP required to reach the current level
        let nextLevelXP = getNextLevelXP(currentLevel);
    
        // Determine the level and calculate cumulative XP
        while (totalXP >= cumulativeXP + nextLevelXP) {
            currentLevel += 1;
            cumulativeXP += nextLevelXP; // Add the XP needed for the current level
            nextLevelXP = getNextLevelXP(currentLevel); // Get XP for the next level
        }
    
        const xpTowardNextLevel = totalXP - cumulativeXP; // XP progress in the current level
    
        console.log(`Total XP: ${totalXP}, Current Level: ${currentLevel}, XP for Next Level: ${nextLevelXP}, XP toward Next Level: ${xpTowardNextLevel}`);
    
        // Update state
        setUserXP(xpTowardNextLevel); // XP within the current level
        setUserLevel(currentLevel);
        setNextLevelXP(nextLevelXP);
    };
    

    const fetchPortraits = async (address) => {
        setIsFetching(true);
        try {
            const [pagesResult, courtiersResult, royalsResult] = await Promise.all([
                fcl.query({
                  cadence: getModelDetails,
                  args: (arg, t) => [arg(address, t.Address)],
                }),
                fcl.query({
                  cadence: getCourtierDetails,
                  args: (arg, t) => [arg(address, t.Address)],
                }),
                fcl.query({
                  cadence: getRoyalDetails,
                  args: (arg, t) => [arg(address, t.Address)],
                }),
              ]);
              
    
              const allPortraits = [...pagesResult, ...courtiersResult, ...royalsResult].map(portrait => {
                const enriched = enrichTraits(portrait.nftData.name, portrait.nftData.traits || []);
                const isRoyal = royalsData.Royals.find(r => r.Name.toLowerCase() === portrait.nftData.name.toLowerCase());
                const imageKey = isRoyal?.CID || portrait.nftData.image; // fallback to existing image if not a royal
              
                return {
                  ...portrait,
                  nftData: {
                    ...portrait.nftData,
                    image: imageKey, // 👈 override image field for royals
                  },
                  enrichedTraits: enriched
                };
              });
              
    
            console.log("All Portraits: ", allPortraits);
    
            // Merge owned and staked NFTs
            const mergedPortraits = mergePortraits(allPortraits, stakedPortraits);
    
            console.log("Merged Portraits: ", mergedPortraits);
    
            setPortraits(mergedPortraits);
            calculateUserLevelAndXP(mergedPortraits);
        } catch (error) {
            console.error('Error fetching portraits:', error);
        } finally {
            setIsFetching(false);
        }
    };
    
    const fetchStakedPortraits = async (address) => {
        try {
            const stakedResult = await fcl.query({
                cadence: getStakedDetails,
                args: (arg, t) => [arg(address, t.Address)]
            });
    
            console.log("Staked NFTs: ", stakedResult);
    
            const enrichedStaked = stakedResult.map(stakedNFT => ({
                ...stakedNFT,
                enrichedTraits: enrichTraits(stakedNFT.nftName, stakedNFT.traits || []),
                isStaked: true
            }));
    
            setStakedPortraits(enrichedStaked);
        } catch (error) {
            console.error('Error fetching staked NFTs:', error);
        }
    };
    
    const mergePortraits = (owned, staked) => {
        const allPortraits = [...owned];
        console.log("Staked NFTs for merging: ", staked);
    
        staked.forEach(stakedNFT => {
            // ✅ Check if the staked NFT is already in the owned portraits by BOTH nftID and nftName
            const ownedPortrait = owned.find(ownedNFT => 
                ownedNFT.nftData.name === stakedNFT.nftName && 
                ownedNFT.nftData.id === stakedNFT.nftID
            );
    
            // Extract the last name and convert to lowercase
            const nameParts = stakedNFT.nftName.split(" ");
            const lastName = nameParts[nameParts.length - 1].toLowerCase();
            console.log(`Looking for image with last name: ${lastName}`);
    
            // Look for the image by checking if the last name is included in any image key
            const matchingImageKey = Object.keys(courtierImages).find(imageKey => 
                imageKey.toLowerCase().includes(lastName)
            );
    
            // Get the image as a string, like 'BaronessPoppyFairweather'
            const image = matchingImageKey || 'defaultCourtierImage';
            console.log(`Matched Image Key: ${matchingImageKey}`);
            console.log(`Image URL: ${image}`);
    
            // Cross-reference with JSON data for complete details
            const matchingPage = pagesData.Pages.find(page => page.alt.split(' - ')[0].trim().toLowerCase() === stakedNFT.nftName.toLowerCase());
            const matchingCourtier = courtiersData.Courtiers.find(courtier => courtier.Name.toLowerCase() === stakedNFT.nftName.toLowerCase());
    
            // Get traits from the JSON data if they exist
            let traits = [];
            let description = '';
            let type = ''; // Distinguish between Page and Courtier
    
            if (matchingPage) {
                traits = matchingPage.traits || [];
                description = matchingPage.description || '';
                type = 'page';
            } else if (matchingCourtier) {
                traits = matchingCourtier.traits || [];
                description = matchingCourtier.Description || '';
                type = 'courtier';
            }
    
            // ✅ Check for matching nftID and nftName
            if (ownedPortrait) {
                // If both nftID and nftName match, increment XP by 1 and mark it as staked
                ownedPortrait.level += 1;
                ownedPortrait.isStaked = true;
            } else {
                // If either nftID or nftName does NOT match, append as a new entry
                const formattedStakedNFT = {
                    nftData: {
                        name: stakedNFT.nftName,
                        id: stakedNFT.nftID, // ✅ Include the nftID to ensure uniqueness
                        image: image,
                        traits: traits,
                        description: description
                    },
                    enrichedTraits: traits, // Traits from JSON
                    level: stakedNFT.level,
                    stakeTimestamp: stakedNFT.stakeTimestamp,
                    isStaked: true,
                    type: type  // Mark whether it's a page or courtier
                };
                allPortraits.push(formattedStakedNFT);
            }
        });
    
        console.log("Merged Portraits Before Final Check: ", allPortraits);
    
        // ✅ Final step: Go through all portraits and set isStaked where name matches a staked NFT
        staked.forEach(stakedNFT => {
            allPortraits.forEach(portrait => {
                if (portrait.nftData.name === stakedNFT.nftName) {
                    portrait.isStaked = true;
                }
            });
        });
    
        console.log("Final Merged Portraits with Staked Status: ", allPortraits);
        return allPortraits;
    };
    
    
    const enrichTraits = (name, traits) => {
        console.log("Enriching traits for:", name);
        
        // Default to an empty array if traits is not already an array
        let enrichedTraits = Array.isArray(traits) ? traits : [];
    
        // Check if the name is in courtiersData (case-insensitive matching)
        const courtier = courtiersData.Courtiers.find(courtier => courtier.Name.toLowerCase() === name.toLowerCase());
        if (courtier) {
            console.log(`Courtier match found for ${name}:`, courtier);
            enrichedTraits = courtier.traits;
            console.log(`Traits from courtiers.json for ${name}:`, enrichedTraits);
        } else {
            console.log(`No courtier match found for ${name}. Checking pages.json...`);
            // If not a courtier, check pages.json
            const page = pagesData.Pages.find(page => page.alt.split(' - ')[0].trim().toLowerCase() === name.toLowerCase());
            if (page) {
                console.log(`Page match found for ${name}:`, page);
                enrichedTraits = page.traits;
                console.log(`Traits from pages.json for ${name}:`, enrichedTraits);
            } else {
                console.warn(`No match found in courtiers.json or pages.json for ${name}.`);
            }
        }
    
        // Return the final enriched traits
        // console.log(`Final enriched traits for ${name}:`, enrichedTraits);
        return enrichedTraits;
    };
    
    
    // Step 3: Fetch Tit balance for each Flow address in the mapping, with logging
    // const fetchBalances = async (mapping) => {
    //     console.log("Fetching balances for mapping:", mapping);
    //     const balances = {};
    //     for (const nftId in mapping) {
    //         const flowAddress = mapping[nftId];  // Get the Flow address from the mapping
    //         if (flowAddress) {
    //             const balance = await fcl.query({
    //                 cadence: getTitBalance,
    //                 args: (arg, t) => [arg(flowAddress, t.Address)]
    //             });
    //             balances[flowAddress] = balance;  // Store balance against Flow address
    //         }
    //     }
    //     console.log("Balances fetched:", balances);
    //     setBalances(balances);  // Save the balances
    // };

    // // Match portrait name with the first part of the alt in popupImages
    // const getMatchingImageData = (portraitName) => {
    //     const matchingImage = images.popupImages.find(image => {
    //         const [imageName] = image.alt.split(' - ');  // Take only the part before ' - '
    //         return imageName === portraitName;
    //     });
    //     console.log(`Matching image data for ${portraitName}:`, matchingImage);
    //     return matchingImage || null;
    // };


    const renderImage = (thumbnailOrCID, type) => {
        if (!thumbnailOrCID || thumbnailOrCID === "N/A") return "";
    
        // Pages have length 7 traits
        if (type === 7) return pageImages[thumbnailOrCID];
        // Courtiers have length 8 traits
        if (type === 8) return courtierImages[thumbnailOrCID];
        // Royals have length 9 traits
        if (type === 9) return royalImages[thumbnailOrCID];
    
        // fallback if type is unreliable — try all maps in order
        return (
            royalImages[thumbnailOrCID] ||
            courtierImages[thumbnailOrCID] ||
            pageImages[thumbnailOrCID] ||
            ""
        );
    };
    
    
    
    
    
    const renderTrait = (traitKey, trait) => {
        // console.log(`Rendering trait with key ${traitKey}:`, trait);
        
        if (!trait || typeof trait !== 'object') {
            console.warn('Invalid trait:', trait);
            return null;
        }
    
        const symbol = trait.modifier && trait.modifier.includes('multiply') ? 'x' : 
                       trait.modifier && trait.modifier.includes('increase') ? '+' : '';
        const valueWithSymbol = symbol ? `${symbol} ${trait.value}` : trait.value;
    
        return (
            <div key={traitKey} className="trait">
                <div className="trait-row-1">
                    <div className="trait-category-name">
                        {trait.category}: {trait.name}
                    </div>
                    <div className="trait-value">
                        {valueWithSymbol}
                    </div>
                </div>
                <div className="trait-row-2">
                    <div className="trait-description">
                        <small>{trait.description}</small>
                    </div>
                </div>
            </div>
        );
    };
    
    
        // Step 5: Render stars based on the exponential growth formula
        const renderStars = (level) => {
            let stars = 0;
            let requiredCount = 5;
        
            // Exponential growth to calculate the number of stars
            while (level >= requiredCount && stars < 5) {
                stars += 1;
                requiredCount = 5 * (2 ** (stars - 1));  // Exponentially increase the number of portraits needed for next star
            }
        
            // Render stars based on the number of stars calculated
            return Array.from({ length: 5 }).map((_, index) => (
                <svg
                    key={index}
                    className={`star ${index < stars ? 'filled' : ''}`}  // Fill stars based on count
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <polygon points="12,2 15,9 22,9 17,14 18,21 12,17 6,21 7,14 2,9 9,9" />
                </svg>
            ));
        };
        
        // Step 4: Group portraits by name and count occurrences
        const groupPortraitsByName = (portraits) => {
            const portraitCount = {};
            portraits.forEach((portrait) => {
                const name = portrait.nftData.name; // Access portrait name
                portraitCount[name] = (portraitCount[name] || 0) + 1; // Increment count
            });
            return portraitCount;
        };
        

    const normalizeTraits = (traits) => {
        // console.log("Normalizing traits:", traits);
        
        // Ensure traits is an array
        if (!Array.isArray(traits)) {
            console.warn('Traits is not an array:', traits);
            return [];
        }
    
        const selectedTraits = traits
            .map((trait, index) => {
                // console.log(`Rendering trait ${index}:`, trait);
                return renderTrait(index, trait);
            })  // Map the traits array to render them
            .filter(Boolean);  // Remove any null or undefined values
    
        // console.log("Selected traits for randomization:", selectedTraits);
    
        // Randomly select two traits
        const randomTraits = selectedTraits.sort(() => 0.5 - Math.random()).slice(0, 2);
        // console.log("Randomly selected traits:", randomTraits);
    
        return randomTraits;
    };
    
    
    const getCourtierName = (name, type) => {
        if (type === 7) return name; // Pages: always show full name
    
        const knownTitles = ["Lady", "Dame", "Countess", "Baroness", "Marchioness"];
        const nameParts = name.trim().split(" ");
    
        if (knownTitles.includes(nameParts[0]) && nameParts.length >= 3) {
            const firstName = nameParts[1];
            const lastName = nameParts.slice(2).join(" ");
            return `${firstName.charAt(0)}. ${lastName}`;
        }
    
        // Fallback: name doesn't start with a known title — use full name
        return name;
    };
    
    
    const renderPortraitTile = (portrait, count, index) => {
        if (!portrait) return null;
    
        const name = getCourtierName(portrait.nftData.name);
        const imageSrc = renderImage(portrait.nftData.image, portrait.nftData.traits.length);
    
        const cardXP = count; // Total XP for the card
        let cardLevel = 0;
        let cumulativeXP = 0; // Total XP required to reach the current level
        let cardNextLevelXP = getNextLevelXP(cardLevel);
    
        // Determine the level and calculate cumulative XP
        while (cardXP >= cumulativeXP + cardNextLevelXP) {
            cardLevel += 1;
            cumulativeXP += cardNextLevelXP; // Add the XP needed for the current level
            cardNextLevelXP = getNextLevelXP(cardLevel); // Get XP for the next level
        }
    
        const xpTowardNextLevel = cardXP - cumulativeXP; // XP progress in the current level
        const xpPercentage = Math.min((xpTowardNextLevel / cardNextLevelXP) * 100, 100); // Fill percentage for XP bar
    
        // Calculate HP by summing up trait values
        const hpValue = portrait.enrichedTraits.reduce((sum, trait) => {
            const value = parseFloat(trait.value) || 0;
            return sum + value;
        }, 0);
    
        const randomTraits = normalizeTraits(portrait.enrichedTraits);
    
        // ✅ Apply holographic class for staked NFTs only
        const cardClass = portrait.isStaked ? "portrait-tile holographic-card" : "portrait-tile";
    
        return (
            <div key={index} className={cardClass}>
                <div className="name-hp-container">
                    <h3>{name}</h3>
                    <div className="hp-container">
                        <span className="hp-value">{hpValue}</span>
                        <span className="hp-label">HP</span>
                    </div>
                </div>
    
                {imageSrc ? (
                    <div className="image-banner">
                        <img src={imageSrc} alt={portrait.nftData.name} />
                        <div className="description-banner">{portrait.nftData.description}</div>
                    </div>
                ) : (
                    <p>Image not found</p>
                )}
                <div className="portrait-details">
                    {randomTraits.length > 0 ? randomTraits : <span>No traits available</span>}
                </div>
                <div className="xp-bar-container">
                    <div className="xp-bar">
                        <div className="xp-bar-fill" style={{ width: `${xpPercentage}%` }} />
                    </div>
                    <div className="xp-info">
                        <span className="level-info">Level {cardLevel}</span>
                        <span className="xp-info-right">{xpTowardNextLevel}/{cardNextLevelXP} XP</span>
                    </div>
                </div>
            </div>
        );
    };
    
    


    const pagesPortraits = portraits.filter(p => {
        return pagesData.Pages.some(page => page.alt.split(' - ')[0].trim().toLowerCase() === p.nftData.name.toLowerCase());
    });
    
    const courtiersPortraits = portraits.filter(p => {
        return courtiersData.Courtiers.some(courtier => courtier.Name.toLowerCase() === p.nftData.name.toLowerCase());
    });

    const royalsPortraits = portraits.filter(p => {
        return royalsData.Royals.some(royal => royal.Name.toLowerCase() === p.nftData.name.toLowerCase());
    });
      
    
    const renderXPBar = () => {
        const xpForNextLevel = nextLevelXP || userXP;  // Handle edge case when user reaches max level
        const xpPercentage = (userXP / xpForNextLevel) * 100;
    
        return (
            <div className="xp-bar-container">
                <div className="xp-bar">
                    <div className="xp-bar-fill" style={{ width: `${xpPercentage}%` }} />
                </div>
                <div className="xp-info">
                    <span className="level-info">Level {userLevel}</span>
                    <span className="xp-info-right">{userXP}/{xpForNextLevel} XP</span>
                </div>
            </div>
        );
    };
    
    

    return (
        <div>
            <div className="homepage-header-landing">
                <h1>Parlor</h1>
            </div>
    
            {/* 🔹 Check if user is logged in BEFORE any fetching */}
            {user.loggedIn === false ? (
                <div className="login-prompt">
                    <h2>Please log in to view your Tit Palace collectibles.</h2>
                    <button onClick={() => fcl.authenticate()} className="login-button">Log In</button>
                </div>
            ) : (
                <>
                    {isFetching ? (
                        <div className="loading-container">
                            <ThreeDots height="80" width="80" color="#007d7e" ariaLabel="loading-indicator"/>
                        </div>
                    ) : portraits.length === 0 ? (
                        <h2 style={{ textAlign: "center", marginTop: "20px" }}>
                            Please log in to view your Tit Palace collectibles.
                        </h2> 
                    ) : (
                        <>
                            {/* Pages Section */}
                            <div className="courtiers-section">
                                <h2>Pages</h2>
                                <div className="portrait-grid">
                                    {Object.entries(groupPortraitsByName(pagesPortraits)).map(([name, count], index) => {
                                        const portrait = portraits.find((p) => p.nftData.name === name);
                                        return renderPortraitTile(portrait, count, index);
                                    })}
                                </div>
                            </div>
    
                            <div className="courtiers-section-real">
                                <h2>Courtiers</h2>
                                <div className="portrait-grid">
                                    {Object.entries(groupPortraitsByName(courtiersPortraits)).map(([name, count], index) => {
                                        const portrait = portraits.find((p) => p.nftData.name === name);
                                        return renderPortraitTile(portrait, count, index);
                                    })}
                                </div>
                            </div>

                            <div className="courtiers-section-real">
                                <h2>Royals</h2>
                                <div className="portrait-grid">
                                    {Object.entries(groupPortraitsByName(royalsPortraits)).map(([name, count], index) => {
                                    const portrait = portraits.find((p) => p.nftData.name === name);
                                    return renderPortraitTile(portrait, count, index);
                                    })}
                                </div>
                            </div>

                        </>
                    )}
                </>
            )}
        </div>
    );
    
}
