export const stakePortraits = `
import NonFungibleToken from 0x1d7e57aa55817448
import TitStakingX0X from 0xfdfe39186c5e3b90
import CourtiersX from 0xfdfe39186c5e3b90
import PagesX from 0xfdfe39186c5e3b90

transaction(nftIds: [UInt64], nftTypes: [String], nftNames: [String], levels: [UInt64]) {
let nfts: @{Int: {NonFungibleToken.NFT}}
let address: Address
let stakingRef: &{TitStakingX0X.TitStakePublic}

    prepare(signer: auth(Storage, BorrowValue, Capabilities) &Account) {
        // Ensure the user has a staking resource; if not, create one.
        if signer.storage.borrow<&TitStakingX0X.TitStake>(from: TitStakingX0X.StakingStoragePath) == nil {
            let staking <- TitStakingX0X.createTitStaking(owner: signer.address)
            signer.storage.save(<-staking, to: TitStakingX0X.StakingStoragePath)
            signer.capabilities.publish(
                signer.capabilities.storage.issue<&{TitStakingX0X.TitStakePublic}>(TitStakingX0X.StakingStoragePath),
                at: TitStakingX0X.StakingPublicPath
            )
        }

        self.address = signer.address
        self.nfts <- {}

        // Ensure that an assets dictionary exists for this owner.
        if !TitStakingX0X.hasAssets(owner: signer.address) {
            TitStakingX0X.createAssetsDictionary(owner: signer.address)
        }

        // Borrow the staking resource.
        self.stakingRef = signer.storage.borrow<&TitStakingX0X.TitStake>(from: TitStakingX0X.StakingStoragePath)
            ?? panic("Staking resource not found!")

        // Borrow the user's NFT collection (must implement NonFungibleToken.Provider).
        let collectionRefCourtiers = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/courtiersXCollection)
            ?? panic("Could not borrow a reference to the NFT collection")

        // Borrow the user's NFT collection (must implement NonFungibleToken.Provider).
        let collectionRefPages = signer.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: /storage/pagesXCollection)
            ?? panic("Could not borrow a reference to the NFT collection")

        // Ensure all input arrays are of equal length.
        if nftIds.length != nftTypes.length || nftIds.length != nftNames.length {
            panic("Input arrays must be of equal length")
        }

        for i in [nftTypes.length -1] {
            // if the type is pages, the we'll use the pages collection ref and if the type is courtiers, then we'll use the courtiers collection ref
            // Withdraw the NFT from the user's collection.
            if nftTypes[i] == "pages" {
                self.nfts[i] <-! collectionRefPages.withdraw(withdrawID: nftIds[i])
            } else if nftTypes[i] == "courtiers" {
                self.nfts[i] <-! collectionRefCourtiers.withdraw(withdrawID: nftIds[i]) 
            } else {
                panic("Unsupported NFT type") 
            }
        }



        

    }

    execute {

        // Loop over each NFT to stake.
        for i in [nftIds.length - 1] {
            let id = nftIds[i]
            let type = nftTypes[i]
            let name = nftNames[i]
            let level = levels[i]

            let nft <- self.nfts.remove(key: i)
                ?? panic("NFT not found in dictionary")

                // Stake the NFT with its level.
                self.stakingRef.stakeNFT(
                    nft: <- nft,
                    nftID: id,
                    nftType: type,
                    nftName: name,
                    level: level,
                    owner: self.address
                )

        }

        destroy self.nfts
    }
}
    `
