import './homepagex.css';
import React, { useState, useEffect } from "react";
import * as fcl from '@blocto/fcl';
import pageImages from '../../assets/pageImages';
import { getPageDetails } from '../../cadence/scripts/getPageDetails';
import { ThreeDots } from 'react-loader-spinner';
import pagesData from '../../cadence/data/pages.json';
import CardPile from './cardPile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';


// Leveling system constants
const maxLevel = 15;
const baseXP = 5;
const growthFactor = 2;

// XP thresholds per level
const levelsXP = Array.from({ length: maxLevel }, (_, level) =>
    Math.floor(baseXP * Math.pow(growthFactor, level))
);

// Function to determine the current level based on XP
const getCurrentLevel = (totalXP) => {
    let level = 0;
    while (level < levelsXP.length && totalXP >= levelsXP[level]) {
        level++;
    }
    return Math.max(level - 1, 0);
};



function LandingPage() {
    const [pageDetails, setPageDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cardStates, setCardStates] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(null); // Tracks which card is leveling
    const [showTextSequence, setShowTextSequence] = useState(false); // Toggle for text sequence
    const [cards, setCards] = useState([]);

    // On mount, load the first 13 cards from pagesData
    useEffect(() => {
      // Limit to 13 cards
      const pages = pagesData.Pages.slice(0, 13);
      setCards(pages);
    }, []);
  
    // Helper to split the alt text into a name and a description.
    const parseAltText = (alt) => {
      const parts = alt.split(' - ');
      return {
        name: parts[0].trim(),
        description: parts[1] ? parts[1].trim() : ''
      };
    };


    useEffect(() => {
        fetchPageDetails();
    }, []);


    useEffect(() => {
        // When cards are loaded and we are not showing the text sequence,
        // start the card leveling animation.
        if (!loading && cardStates.length > 0 && !showTextSequence) {
          startLevelUpSequence();
        }
      }, [loading, showTextSequence]);
        // then switch back to the card animation.

    useEffect(() => {
        if (showTextSequence) {
        // Adjust the timeout to match your animation durations + desired 3-second delay.
        const timer = setTimeout(() => {
            // Reset all cards to idle state.
            setCardStates((prevStates) =>
            prevStates.map((card) => ({
                ...card,
                progress: 0,
                buttonState: "BUY",
                flashButton: false,
                status: "idle",
            }))
            );
            setCurrentCardIndex(null);
            setShowTextSequence(false);
            startLevelUpSequence(); // Restart the card animation cycle.
        }, 5000 + 1500); // Example: 1500ms for text animation + 3000ms delay
        return () => clearTimeout(timer);
        }
    }, [showTextSequence]);

    

    // Fetch NFT page details
    const fetchPageDetails = async () => {
        setLoading(true);
        try {
        //     const pages = await fcl.query({
        //         cadence: getPageDetails,
        //         args: (arg, t) => [arg("0xfdfe39186c5e3b90", t.Address)],
        //     });

        //     const uniquePages = [...new Map(pages.map(item => [item.name, item])).values()];
        //     const randomPages = uniquePages.sort(() => 0.5 - Math.random()).slice(0, 4);

            // Use the local JSON data instead of fetching from Flow
        const pages = pagesData.Pages; // Assuming the JSON structure is { "Pages": [ ... ] }

        const randomPages = pages.sort(() => 0.5 - Math.random()).slice(0,4);
    

            const initialStates = randomPages.map((page) => ({
                page,
                totalXP: Math.floor(Math.random() * levelsXP[maxLevel - 1]), // Assign random XP
                level: getCurrentLevel(Math.random() * levelsXP[maxLevel - 1]),
                progress: 0,
                buttonState: "BUY",
                flashButton: false,
                status: "idle",
            }));

            setPageDetails(randomPages);
            setCardStates(initialStates);
        } catch (error) {
            console.error("Failed to fetch page details:", error);
        } finally {
            setLoading(false);
        }
    };

    const startLevelUpSequence = async () => {
        for (let i = 0; i < cardStates.length; i++) {
            await levelUpCard(i); // ✅ Wait for each card before moving to the next
            console.log(`✅ Card ${i + 1} finished leveling up.`);
        }
    
        console.log("✅ All cards leveled up, restarting loop...");
    

        // Instead of immediately restarting the card animation,
        // show the text sequence.
        setShowTextSequence(true);
    };
    
    
    const levelUpCard = (index) => {
        return new Promise((resolve) => {
            let step = 0;
            const maxSteps = 3;
    
            // Set active card index and start leveling
            setCurrentCardIndex(index);
            setCardStates((prevStates) =>
                prevStates.map((card, i) =>
                    i === index ? { ...card, status: "leveling", progress: 0, buttonState: "BUY" } : card
                )
            );
    
            const interval = setInterval(() => {
                setCardStates((prevStates) =>
                    prevStates.map((card, i) => {
                        if (i === index) {
                            let newProgress = Math.min(card.progress + 33.33, 100);
                            return {
                                ...card,
                                progress: newProgress,
                                flashButton: true, // 🔥 Flashing effect with progress
                            };
                        }
                        return card;
                    })
                );
    
                // Stop flashing after 400ms
                setTimeout(() => {
                    setCardStates((prevStates) =>
                        prevStates.map((card, i) =>
                            i === index ? { ...card, flashButton: false } : card
                        )
                    );
                }, 400);
    
                step++;
                if (step >= maxSteps) {
                    clearInterval(interval);
    
                    // 🛑 **Ensure the card stays in "LEVELED UP!" state**
                    setTimeout(() => {
                        setCardStates((prevStates) =>
                            prevStates.map((card, i) =>
                                i === index
                                    ? { ...card, status: "done", buttonState: "LEVELED UP!", progress: 100 }
                                    : card
                            )
                        );
    
                        resolve(); // ✅ Only move to the next card after animation finishes
                    }, 800);
                }
            }, 800);
        });
    };
    
    
    const renderImage = (thumbnail) => {
        if (!thumbnail || thumbnail === "N/A") return "";
        return pageImages[thumbnail] || "";
    };

    const calculateHP = (traits) => {
        return traits.reduce((total, trait) => total + (parseFloat(trait.value) || 0), 0);
    };

    const formatDescription = (description) => {
        if (!description) return "";
        const words = description.split(" ");
        if (words[0].toLowerCase() === "the") {
            return words.slice(1).join(" ");
        }
        return description;
    };
    return (
        <div className="homepage">
              <div className="homepage-header-landing">
                <h1>New Users</h1>
                <p className="header-sentence-1">
                    Buy 1 card, 💗 <br></br>get <span className="highlight-main"> 5 </span>free! 
                </p>
                <p className="header-sentence-2">
                    adult nfts made easy 💕.
                </p>
                <a
                  href="https://x.com/vertico_defi/status/1889233228906566081"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="buy-button-x"
                >
                  Claim on <FontAwesomeIcon icon={faXTwitter} className="x-logo" />!
                </a>
            </div>
          <div className="content-container">
            <div className="left-column-landing">
                <CardPile />
            </div>
       {/* <div className="image-split">
                <div className="row"></div>
                <div className="row"></div>
                <div className="row"></div>
                <div className="row"></div>
                <div className="row"></div>
            </div> */}

    
            <div className="right-column-landing">
            {loading ? (
                // Wrap loading indicators inside the grid container so they display 2x2.
                <div className="featured-card-grid">
                {[...Array(4)].map((_, index) => (
                    <div key={index} className="featured-card loading">
                    <ThreeDots
                        height="40"
                        width="80"
                        color="#ffe0e9"
                        ariaLabel="loading-indicator"
                    />
                    </div>
                ))}
                </div>
              ) : showTextSequence ? (
                // Show the text sequence when triggered.
                <div className="text-sequence">
                <div className="text top"><span className='top-h'>Rule Tit Palace</span><br></br> <span className='top-m'>#onFlow</span><br></br><span className='top-b'>in 3 Easy Steps</span></div>
                <div className="text middle">
                    <span className="highlight">Login</span> 🩷<br />
                    <span className="highlight">Buy</span> Cards<br />
                    <span className="highlight">Gain</span> XP 

                </div>
                <div className="text bottom">Join the Palace Today!</div>
                </div>
              ) : (
                // Otherwise, show the card grid.
                <div className="featured-card-grid">
                  {cardStates.map((card, index) => (
                    <div
                      key={index}
                      className={`featured-card ${index === currentCardIndex ? "active-flash" : ""}`}
                    >
                      <div className="card-header">
                      <span className="card-name">{card.page.alt.split(" - ")[0]}</span>
                        <span className="card-hp">{calculateHP(card.page.traits)} HP</span>
                      </div>
                      <img
                        src={pageImages[card.page.src]}
                        alt={card.page.name}
                        className="card-image"
                      />
    
                      <div className="card-banner">
                        {card.status === "leveling" || card.status === "done" ? (
                          <div className="progress-bar" style={{ width: `${card.progress}%` }}></div>
                        ) : (
                          <>
                            <p className="card-description">{card.page.alt.split(" - ")[1]}</p>
                            <span className="trait-value-landing">
                              {card.page.traits.find(t => t.category === "Tits")?.name || "Unknown"}
                            </span>
                            <span className="trait-label"> Tits</span>
                          </>
                        )}
                      </div>
    
                      <div className={`buy-button ${card.flashButton ? "flashing" : ""}`}>
                        {card.buttonState}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='marketing-long'>
            <h1>Join the Action & <br></br> Level Up<span className="highlight-main"> at </span>Tit Palace</h1>
            <p>At Tit Palace, it's all about building your collection one card at a time. Purchase exclusive adult NFT cards in the Bazaar, then head to your personal Parlor to watch them evolve and showcase their progress. Every card's level is displayed right in your Parlor—so you know your collection is growing stronger with every purchase.</p>
            <p>It's simple: buy your card, watch it progress, and join a community celebrating every achievement. Step into Tit Palace and transform your collection into a statement of style and success! </p>
          </div>
          <div class="action-container">

            <a href="/bazaar" class="action-link">
              <div class="action-item">
                <div class="action-icon">💸</div>
                <div class="action-text">
                  <h3>Buy</h3>
                  <p>Buy exclusive cards and fortify your collection—each purchase is a bold move that sets your style apart.</p>
                </div>
              </div>
            </a>
            <a href="/parlor" class="action-link">
              <div class="action-item">
                <div class="action-icon">⬆️</div>
                <div class="action-text">
                  <h3>Level Up</h3>
                  <p>Level up your cards to legendary status—watch your collection soar as every card rises to greatness.</p>
                </div>
              </div>
            </a>
            <a href="https://blocto.io" target="_blank" rel="noopener noreferrer" class="blocto-link">
              <div class="action-item">
                <div class="action-icon">💰</div>
                <div class="action-text">
                  <h3>Stake & Earn</h3>
                  <p>
                    Lock in your leveled-up cards and stake them to earn TIT$—watch your treasures grow as you claim your share of the palace's wealth.
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="tutorial-section">
            <h2>Get Flow &amp; Rule Tit Palace</h2>
            <p>
              Not sure how to fund your Blocto wallet? We’ve got you covered! Check out our exclusive tutorial on X that walks you through every step of getting Flow so you can start purchasing Tit Palace collectibles.
            </p>
            <a
              href="https://x.com/vertico_defi/status/1889953340685164763" 
              target="_blank" 
              rel="noopener noreferrer"
              className="buy-button-x"
            >
              View Tutorial on <FontAwesomeIcon icon={faXTwitter} className="x-logo" />
            </a>
          </div>
        </div>
      );
}

export default LandingPage;
