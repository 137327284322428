import React, { useMemo } from 'react';
import pageImages from '../../assets/pageImages';
import pagesData from '../../cadence/data/pages.json';
import './homepagex.css'

// Leveling system constants
const maxLevel = 15;
const baseXP = 5;
const growthFactor = 2;

const getNextLevelXP = (level) => Math.floor(baseXP * Math.pow(growthFactor, level));

const getCurrentLevel = (totalXP) => {
  let level = 0;
  while (level < maxLevel && totalXP >= getNextLevelXP(level)) {
    totalXP -= getNextLevelXP(level);
    level++;
  }
  return level;
};

const calculateHP = (traits) => 
  traits.reduce((total, trait) => total + (parseFloat(trait.value) || 0), 0);

const getRandomTraits = (traits) => {
  if (!traits || !Array.isArray(traits)) return [];
  const shuffled = [...traits].sort(() => Math.random() - 0.5);
  return shuffled.slice(0, 2);
};

const parseAltText = (alt) => {
  const parts = alt.split(' - ');
  return {
    name: parts[0].trim(),
    description: parts[1] ? parts[1].trim() : ''
  };
};

const CardPile = () => {
  // Precompute the card data (limit to 13 cards, assign random XP, and randomly select 2 traits)
  const computedCards = useMemo(() => {
    return pagesData.Pages.slice(0, 13).map(page => {
      const xp = Math.floor(Math.random() * 100);
      const randomTraits = getRandomTraits(page.traits);
      return { ...page, xp, randomTraits };
    });
  }, []);

  // Precompute random styles (position and rotation) once.
  const randomStyles = useMemo(() => {
    return computedCards.map(() => {
      const left = Math.random() * 80; // between 0% and 80%
      const top = Math.random() * 80;  // between 0% and 80%
      const rotation = (Math.random() * 30) - 15; // between -10° and 10°
      return { left, top, rotation };
    });
  }, [computedCards.length]);

  return (
    <div className="card-pile-container">
      {computedCards.map((card, index) => {
        const { left, top, rotation } = randomStyles[index] || { left: 0, top: 0, rotation: 0 };
        const { name: cardName, description: cardDescription } = parseAltText(card.alt);
        const hpValue = calculateHP(card.traits);

        // Compute level & XP info based on the stored card.xp
        const cardXP = card.xp;
        const cardLevel = getCurrentLevel(cardXP);
        const nextLevelXP = getNextLevelXP(cardLevel);
        const xpTowardNextLevel = cardXP % nextLevelXP;
        const xpPercentage = Math.min((xpTowardNextLevel / nextLevelXP) * 100, 100);

        return (
          <div
            key={index}
            className="card-pile-item"
            style={{
              position: 'absolute',
              left: `${left}%`,
              top: `${top}%`,
              transform: `rotate(${rotation}deg)`
            }}
          >
            <div className="name-hp-container-pile">
              <h3>{cardName}</h3>
              <div className="hp-container">
                <span className="hp-value">{hpValue}</span>
                <span className="hp-label">HP</span>
              </div>
            </div>
            <div className="image-banner-pile">
              <img
                src={pageImages[card.src]}
                alt={card.alt}
                className="card-image"
              />
              <div className="description-banner">{cardDescription}</div>
            </div>
            <div className="portrait-details">
              {card.randomTraits.map((trait, idx) => (
                <div key={idx} className="trait">
                  <div className="trait-row-1">
                    <div className="trait-category-name">
                      {trait.category}: {trait.name}
                    </div>
                    <div className="trait-value">{trait.value}</div>
                  </div>
                  <div className="trait-row-2">
                    <div className="trait-description">
                      <small>{trait.description}</small>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="xp-bar-container">
              <div className="xp-bar">
                <div className="xp-bar-fill" style={{ width: `${xpPercentage}%` }} />
              </div>
              <div className="xp-info">
                <span className="level-info">Level {cardLevel}</span>
                <span className="xp-info-right">
                  {xpTowardNextLevel}/{nextLevelXP} XP
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CardPile;
