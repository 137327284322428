import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {config} from '@onflow/fcl';
import dotenv from 'dotenv';
import * as fcl from '@onflow/fcl'
import './lib/fcl/config';


dotenv.config();


if (window.location.protocol !== 'https:') {
  window.location.href = `https:${window.location.href.substring(window.location.protocol.length)}`;
} 

// fcl.config()
// .put("accessNode.api", "https://rest-mainnet.onflow.org")
// .put("discovery.wallet", "https://fcl-discovery.onflow.org/authn")
// .put("flow.network", "mainnet")
// .put("walletconnect.projectId", "8c9205597881feb4cabff72159c1a6de")


// initFclWc(); // Initialize WalletConnect configuration




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
