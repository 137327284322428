export const getRoyalDetails = `
import NonFungibleToken from 0x1d7e57aa55817448
import RoyalsX from 0xfdfe39186c5e3b90

// The struct to hold the ID and the whole NFT reference data for inspection
access(all) struct NFTDetails {
    access(all) let id: UInt64
    access(all) let nftData: AnyStruct

    init(id: UInt64, nftData: AnyStruct) {
        self.id = id
        self.nftData = nftData
    }
}

access(all) fun main(account: Address): [NFTDetails] {
    let collectionRef = getAccount(account).capabilities
        .borrow<&{NonFungibleToken.CollectionPublic, RoyalsX.CollectionPublic}>(RoyalsX.CollectionPublicPath)
        ?? panic("Could not borrow a reference to the collection.")

    let nftIDs = collectionRef.getIDs()
    var nftDetails: [NFTDetails] = []

    for id in nftIDs {
        let nftRef = collectionRef.borrowRoyalsX(id: id) 
            ?? panic("Could not borrow the RoyalsX NFT with the given ID")

        // Store the entire NFT reference for inspection
        let details = NFTDetails(id: id, nftData: nftRef)
        nftDetails.append(details)
    }

    return nftDetails
}



    `
