import royal1 from "./royals/1.png";
import royal2 from "./royals/2.png";
import royal3 from "./royals/3.png";
import royal4 from "./royals/4.png";
import royal5 from "./royals/5.png";
import royal6 from "./royals/6.png";
import royal7 from "./royals/7.png";
import royal8 from "./royals/8.png";
import royal9 from "./royals/9.png";
import royal10 from "./royals/10.png";
import royal11 from "./royals/11.png";
import royal12 from "./royals/12.jpeg";
import royal13 from "./royals/13.jpeg";
import royal14 from "./royals/14.png";
import royal15 from "./royals/15.jpeg";
import royal16 from "./royals/16.jpeg";
import royal17 from "./royals/17.png";
import royal18 from "./royals/18.jpeg";
import royal19 from "./royals/19.png";
import royal20 from "./royals/20.png";
import royal21 from "./royals/21.png";
import royal22 from "./royals/22.png";
import royal23 from "./royals/23.png";
import royal24 from "./royals/24.png";
import royal25 from "./royals/25.png";
import royal26 from "./royals/26.png";
import royal27 from "./royals/27.png";
import royal28 from "./royals/28.png";
import royal29 from "./royals/29.png";
import royal30 from "./royals/30.png";
import royal31 from "./royals/31.png";
import royal32 from "./royals/32.png";
import royal33 from "./royals/33.png";
import royal34 from "./royals/34.png";
import royal35 from "./royals/35.png";
import royal36 from "./royals/36.png";
import royal37 from "./royals/37.png";

const royalImages = {
  img1: royal1,
  img2: royal2,
  img3: royal3,
  img4: royal4,
  img5: royal5,
  img6: royal6,
  img7: royal7,
  img8: royal8,
  img9: royal9,
  img10: royal10,
  img11: royal11,
  img12: royal12,
  img13: royal13,
  img14: royal14,
  img15: royal15,
  img16: royal16,
  img17: royal17,
  img18: royal18,
  img19: royal19,
  img20: royal20,
  img21: royal21,
  img22: royal22,
  img23: royal23,
  img24: royal24,
  img25: royal25,
  img26: royal26,
  img27: royal27,
  img28: royal28,
  img29: royal29,
  img30: royal30,
  img31: royal31,
  img32: royal32,
  img33: royal33,
  img34: royal34,
  img35: royal35,
  img36: royal36,
  img37: royal37,
};

export default royalImages;
