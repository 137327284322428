import React, { useState, useEffect } from 'react';
import './App.css';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import AgeVerificationPopup from "./components/popUp/popUp";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage/homepage';
import LandingPage from './pages/homepage/homepagex';
import TeasersPage from './pages/teasers/teasers';
import ShopPage from './pages/shop/shop';
import Brothel from './pages/brothel/brothel'
import Parlor from './pages/parlor/parlor';

import * as fcl from '@onflow/fcl';
import { ageVerify } from './cadence/transactions/ageVerify';
import { getPalaceBadgesDetails } from './cadence/scripts/getPalaceBadgesDetails';
import { toast } from 'react-toastify';
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/userContext';
import { ListingsProvider } from './contexts/listingsContextNew';
import AfternoonEncounter from './components/stories/afternoonEncounter';
import PaymentPage from './components/payments/paymentPage';
import { GlobalProvider } from './components/globalContext/globalState';
import { checkFlowBalance } from './cadence/scripts/checkFlowBalance'; 

function App() {
  const [user, setUser] = useState({ loggedIn: false });
  const [loading, setLoading] = useState(false);
  const [flowBalance, setFlowBalance] = useState(0);
  

  useEffect(() => {
    const unsubscriber = fcl.currentUser().subscribe(user => {
      setUser({
        loggedIn: user.loggedIn,
        addr: user.addr,
      });
      if (!user.loggedIn) {
        setLoading(false);
      }
    });
    return () => unsubscriber();
  }, []);

  // Function to fetch the Flow balance
  const fetchFlowBalance = async (address) => {
    try {
      const balance = await fcl.query({
        cadence: checkFlowBalance,
        args: (arg, t) => [arg(address, t.Address)]
      });
      setFlowBalance(Number(balance));  // Update the global balance
    } catch (error) {
      console.error("Error fetching Flow Balance:", error);
    }
  };

  useEffect(() => {
    fetchFlowBalance(user.addr);
  }, user.addr)
  

  const handleSignIn = async () => {
    try {
      await fcl.logIn();
      toast.success("Signed in successfully");
    } catch (error) {
      console.error("Error signing in:", error);
      toast.error("Error signing in: " + error.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthProvider>
      <UserProvider>
        <GlobalProvider>
          <ListingsProvider>          
            <Router>
              <div className="header-transparent">
                <Header />
              </div>
              <Routes>

                <Route path="/" element={<LandingPage />} /> {/* Make LandingPage the main page */}
                <Route path="/bazaar" element={<Homepage fetchFlowBalance={fetchFlowBalance} />} />
                <Route path="/tours" element={<TeasersPage />} />
                <Route path="/shop" element={<ShopPage />} />
                <Route path="/brothel" element={<Brothel />} />
                <Route path="/parlor" element={<Parlor />} />
                <Route path="/afternoon-encounter" element={<AfternoonEncounter />} />
                <Route path="/payment" element={<PaymentPage />} />
              </Routes>
              <Footer flowBalance={flowBalance} />
            </Router>
          </ListingsProvider>
        </GlobalProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
