export const getStakedDetails = `
import TitStakingX0X from 0xfdfe39186c5e3b90

access(all) fun main(owner: Address): [TitStakingX0X.StakedNFTMetadata] {

    let account = getAccount(owner)

    let stakingRef = account.capabilities.borrow<&{TitStakingX0X.TitStakePublic}>(TitStakingX0X.StakingPublicPath)
        ?? panic("Could not borrow reference to TitStake public path")

    

    return stakingRef.getStakedNFTDetails(owner: owner)
}

`